import CardsSuccessTemplate  from '../components/CardsSuccessTemplate'

import { useResult } from '../hooks'

const AzulInfiniteCard = () => {
  useResult({})

  return <CardsSuccessTemplate />
}

export default AzulInfiniteCard
